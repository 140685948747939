body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --toolbar-chunk: #f4dbff;
  --title-color: #000;
  --arrow-color: #000;
  --calendar-box: #f4dbff;
  --content-text: #000;
}
[data-theme='dark'] {
  --toolbar-chunk: #713889;
  --title-color: #fff;
  --arrow-color: #fff;
  --calendar-box: #713889;
  --content-text: #fff;
}

body,
#root,
html {
  min-height: 100vh;
}

img {
  width: auto;
  height: auto;
}

*::-webkit-scrollbar {
  width: 13px;
}

*::-webkit-scrollbar-track {
  background: #dedcdc;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #dedcdc;
}

*::-webkit-scrollbar-thumb {
  background-color: #5da6d8;
  border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background-color: #2b83b5;
}

* {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.logoTraumappLogin {
  margin: 10px 0px 10px 15px;
  width: 60px;
  height: 60px;
}

.msgError {
  color: #813b3b;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: 'Open-sans', sans-serif;
  text-align: center;
  margin-bottom: 10px;
}

.cleanBeforeReactSlick::before {
  position: initial !important;
}

.fc-toolbar-chunk {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--toolbar-chunk);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 0px 15px !important;
}

.fc-toolbar-title {
  margin: 15px 0px !important;
  text-transform: uppercase;
  margin-left: 0px !important;
  font-size: 22px !important;
  color: var(--title-color) !important;
}

.fc-button {
  margin: 0px !important;
  background: transparent !important;
  border: 0px !important;
  margin-left: 0px !important;
  box-shadow: 0 0 0 rgb(76 91 106 / 50%) !important;
}

.fc-header-toolbar {
  margin-bottom: 15px !important;
}

.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  text-transform: uppercase;
  color: var(--content-text) !important;
  display: block !important;
  padding: 0px !important;
  position: relative;
  z-index: 4;
  height: 25px !important;
  width: 25px !important;
  margin: auto !important;
}

.fc-media-screen {
  width: 90%;
  margin: auto;
  min-height: 60vh !important;
}

.fc-button:after {
  content: '';
  width: 20px;
  height: 2px;
  display: block;
  transform: rotate(-45deg);
  background: var(--arrow-color) !important;
  margin-top: 12px;
}

.fc-button:before {
  content: '';
  width: 20px;
  height: 2px;
  display: block;
  transform: rotate(45deg);
  background: var(--arrow-color) !important;
}

.fc-prevButton-button {
  transform: rotate(180deg);
}

.fc-scrollgrid-sync-inner {
  width: 5vw;
  position: relative;
}

.fc-scrollgrid,
.fc-col-header,
.fc-scrollgrid-sync-table {
  margin: auto;
  border: 0px !important;
}

.fc-daygrid-day-top {
  text-align: center;
  height: 55px;
  cursor: pointer;
  display: block !important;
  flex-direction: initial !important;
}

.fc-dayGridMonth-view {
  background: var(--calendar-box) !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 25px;
  padding: 12px 5px;
  position: initial !important;
}

.fc-daygrid-day-events {
  position: absolute !important;
  bottom: 25%;
  right: 44%;
  min-height: 0 !important;
}

.fc-event-title {
  height: 8px;
  width: 8px;
  background: red;
  border-radius: 999px;
  display: block !important;
  max-width: initial !important;
  overflow: none !important;
}

.fc-highlight {
  width: 25px !important;
  height: 25px !important;
  background: #00000050 !important;
  border-radius: 999px !important;
  position: initial !important;
}

.fc-daygrid-bg-harness {
  display: flex;
  right: 0px;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  top: 0;
}

.fc-direction-ltr {
  direction: initial !important;
  text-align: initial !important;
}

.fc {
  display: block !important;
  flex-direction: initial !important;
  font-size: initial !important;
}

.fc .fc-toolbar {
  display: block !important;
  justify-content: initial !important;
  align-items: initial !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px !important;
}

.fc .fc-scroller-liquid-absolute {
  position: initial !important;
}

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  background: transparent !important;
}

.fc .fc-daygrid-day-bottom {
  font-size: 0 !important;
  padding: 0 !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}

.fc
  .fc-daygrid-day.fc-day-today
  > .fc-daygrid-day-frame
  > .fc-daygrid-day-top
  > .fc-daygrid-day-number {
  background-color: #310445 !important;
  border-radius: 9999px;
  color: #fff !important;
}

.fc-h-event {
  display: block;
  border: 0px !important;
  background-color: transparent !important;
}

.fc .fc-scroller-harness {
  margin-bottom: 15px !important;
}

@media only screen and (max-width: 1650px) {
  .fc-daygrid-day-events {
    right: 42%;
  }
}

@media only screen and (max-width: 1600px) {
  .fc-daygrid-day-events {
    right: 41%;
  }
}

@media only screen and (max-width: 1550px) {
  .fc-daygrid-day-events {
    right: 40%;
  }
}

@media only screen and (max-width: 1500px) {
  .fc-daygrid-day-events {
    right: 39%;
  }
}

@media only screen and (max-width: 1450px) {
  .fc-daygrid-day-events {
    right: 38%;
  }
}

@media only screen and (max-width: 1400px) {
  .fc-daygrid-day-events {
    right: 39%;
  }
}

@media only screen and (max-width: 1350px) {
  .fc-daygrid-day-events {
    right: 40%;
  }
}

@media only screen and (max-width: 1300px) {
  .fc-daygrid-day-events {
    right: 38%;
  }
}

@media only screen and (max-width: 1250px) {
  .fc-daygrid-day-events {
    right: 39%;
  }
}

@media only screen and (max-width: 1199px) {
  .fc-scrollgrid-sync-inner {
    width: 9vw !important;
  }

  .fc-toolbar-title {
    font-size: 20px !important;
  }

  .fc-button:after {
    width: 15px !important;
    margin-top: 8px !important;
  }

  .fc-button:before {
    width: 15px !important;
  }

  .fc-daygrid-day-events {
    right: 44%;
  }
}

@media only screen and (max-width: 1150px) {
  .fc-daygrid-day-events {
    right: 43%;
  }
}

@media only screen and (max-width: 1100px) {
  .fc-daygrid-day-events {
    right: 42%;
  }
}

@media only screen and (max-width: 1050px) {
  .fc-daygrid-day-events {
    right: 42%;
  }
}

@media only screen and (max-width: 950px) {
  .fc-daygrid-day-events {
    right: 41%;
  }
}

@media only screen and (max-width: 899px) {
  .fc-daygrid-day-events {
    right: 42%;
  }
}

@media only screen and (max-width: 850px) {
  .fc-daygrid-day-events {
    right: 41%;
  }
}

@media only screen and (max-width: 800px) {
  .fc-daygrid-day-events {
    right: 40%;
  }
}

@media only screen and (max-width: 700px) {
  .fc-daygrid-day-events {
    right: 39%;
  }
}

@media only screen and (max-width: 650px) {
  .fc-daygrid-day-events {
    right: 38%;
  }
}

@media only screen and (max-width: 599px) {
  .fc-daygrid-day-events {
    right: 37%;
  }
}

@media only screen and (max-width: 550px) {
  .fc-daygrid-day-events {
    right: 36%;
  }
}

@media only screen and (max-width: 500px) {
  .logoTraumappLogin {
    width: 45px;
    height: 45px;
  }

  .fc-scrollgrid-sync-inner {
    width: 8vw !important;
  }

  .fc-toolbar-title {
    font-size: 16px !important;
  }

  .fc-daygrid-day-events {
    right: 34%;
  }
}

@media only screen and (max-width: 450px) {
  .fc-daygrid-day-events {
    right: 32%;
  }
}

@media only screen and (max-width: 400px) {
  .fc-daygrid-day-events {
    right: 28%;
  }
}

@media only screen and (max-width: 350) {
  .fc-daygrid-day-events {
    right: 24%;
  }
}
